<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{
                  $t("message.new_m", {
                      m: $t("message.company_machines"),
                  })
              }}</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.car_number") }}
                </span>
                <el-form-item prop="number" class="label_mini">
                  <crm-input
                  :class="mode ? 'input__day' : 'input__night'"
                    :inputValue="form.number"
                    v-model="form.number"
                    :placeholder="$t('message.car_number')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                  {{ $t("message.car_model") }}
                </span>
                <!-- <el-form-item prop="guest_car_model_id" class="label_mini">
                  <select-guest-car-model
                    :id="form.guest_car_model_id"
                    v-model="form.guest_car_model_id"
                  ></select-guest-car-model>
                </el-form-item> -->
                 <el-row :gutter="20">
                <el-col :span="20">
                <el-form-item prop="guest_car_model_id" class="label_mini">
                  <select-guest-car-model
                    :id="form.guest_car_model_id"
                    v-model="form.guest_car_model_id"
                  ></select-guest-car-model>
                </el-form-item>
                </el-col>
                <!-- end col -->
                <el-col :span="4">
                  <div class="pulus " @click="dialogCreateModel = true" :class="mode ? 'pulus__myday' : 'pulus__mynight'">
                    <i class="el-icon-plus"></i>
                  </div>
                </el-col>
              </el-row>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.car_color") }}
                  </span>
                  <el-form-item prop="color" class="label_mini">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.color"
                      v-model="form.color"
                      :placeholder="$t('message.car_color')"
                    ></crm-input>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                    $t("message.car_driver")
                  }}</span>
                  <!-- <el-form-item prop="staff_id" class="label_mini">
                    <select-staff
                      :placeholder="$t('message.car_driver')"
                      :id="form.staff_id"
                      v-model="form.staff_id"
                    >
                    </select-staff>
                  </el-form-item> -->
                  <el-form-item prop="driver" class="label_mini">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.driver"
                      v-model="form.driver"
                      :placeholder="$t('message.car_driver')"
                    ></crm-input>
                  </el-form-item>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                    {{ $t("message.line") }}
                  </span>
                  <el-form-item prop="name" class="label_mini">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :inputValue="form.name"
                      v-model="form.name"
                      :placeholder="$t('message.line')"
                    ></crm-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="12">
                  <select-company
                    :id="form.company_id"
                    v-model="form.company_id"
                  ></select-company>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div> 
    </div>
    <el-dialog
      :visible.sync="dialogCreateModel"
      width="40%"
      :append-to-body="true"
      center
      ref="dialogCreateModel"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <crm-create-car-model
        :guest_car_type_id="form.guest_car_type_id"
        ref="dialogCreateModelChild"
        dialog="dialogCreateModel"
      ></crm-create-car-model>
    </el-dialog>
    
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCompany from "@/components/filters/inventory/select-or-hidden-company";
import selectGuestCarModel from "@/components/filters/inventory/select-guest-car-model";
import selectStaff from "@/components/filters/inventory/select-staff";
import CrmCreateCarModel from "./crm-car-create-model.vue";
export default {
  mixins: [form, drawer],
  name: "TransportController",
  components: { selectGuestCarModel, selectStaff, selectCompany, CrmCreateCarModel },
  data() {
    return {
       dialogCreateModel: false,
      loadingButton: false, 
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "transport/rules",
      model: "transport/model",
      columns: "transport/columns",
      mode:"MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "transport/store",
      updateInventory: "transport/inventory",

    }),
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              if (res.status == 201) {
                this.parent().listChanged();
                this.updateInventory();
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>

