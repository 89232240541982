<template>
  <div>
    <el-form ref="form" status-icon :model="form" :rules="rules">
     <el-row :gutter="20">
        <el-col :span="20">
          <el-form-item prop="guest_car_type_id" size="medium">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block"> {{$t('message.car_type')}}  </span>
            <select-guest-car-type
              :id="form.guest_car_type_id"
              v-model="form.guest_car_type_id"
            ></select-guest-car-type>
          </el-form-item>
        </el-col>
        <!-- end col -->
        <el-col :span="4">
          <div class="pulus mts" @click="dialogCreate = true" :class="mode ? 'pulus__myday' : 'pulus__mynight'">
            <i class="el-icon-plus"></i>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item prop="model" size="medium">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block">
              {{ $t("message.car_model") }}
            </span>
            <crm-input
              :inputValue="form.model"
              v-model="form.model"
              :placeholder="$t('message.car_model')"
              :class="mode ? 'input__day' : 'input__night'"
            ></crm-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitModel()"> {{$t('message.save')}} </el-button>
      <el-button type="warning" @click="close()"> {{$t('message.close')}} </el-button>
    </span>



    <el-dialog
      :visible.sync="dialogCreate"
      width="40%"
      :append-to-body="true"
      center
      ref="dialogCreate"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <crm-create-car-type
        ref="dialogCreateChild"
        dialog="dialogCreate"
      ></crm-create-car-type>
    </el-dialog>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import dialog from "@/utils/mixins/dialog";
import selectGuestCarType from "@/components/filters/inventory/select-guest-car-type";
import CrmCreateCarType from "../../visitor/visitor/components/car/crm-car-create-type.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  mixins: [dialog],
  components:{
    CrmCreateCarType
  },
  data() {
    return {
      form: {},
      rules: {},
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters({
      mode:"MODE"
    }),
  },
  methods: {
    ...mapActions({
      save: "guestCarModel/store",
      updateInventory: "guestCarModel/inventory",
    }),
    submitModel() {
      console.log('salom');
      this.$refs["form"].validate((valid) => {
        if (valid && !this.loadingButton && this.form.guest_car_type_id) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.$alert(res);
              if (res.status == 201) {
                this.updateInventory();
                this.close();
                this.$parent.$parent.form.guest_car_model_id =res.data.result.data.guest_car_model_id;
                  
              }
              this.loadingButton = false;
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
